export const LIVE_SERVICE_NAMES = {
    // CR APIs Search
    getChangeRequestSearchGrid: 'getChangeRequestSearchGrid',
    getApplicationPeriodList: 'getApplicationPeriodList',
    getChangeRequestDropdownValues: 'getChangeRequestDropdownValues',
    getDACRDetailsGrid: 'getDACRDetailsGrid',

    //CR APIs Details
    getChangeRequestDetails: 'getChangeRequestDetails',
    getChangeRequestBasicDetails: 'getChangeRequestBasicDetails',
    getUpdateTESCRDetails: 'getUpdateTESCRDetails',
    getMovedPhysicianCRDetails: 'getMovedPhysicianCRDetails',
    getAllocationChangeCRDetails: 'getAllocationChangeCRDetails',
    getAddNewAccountGroupCRDetails: 'getAddNewAccountGroupCRDetails',
    getAddNewPhysicianCRDetails: 'getAddNewPhysicianCRDetails',
    getUpdateAGPhysicianAffiliationCRDetails: 'getUpdateAGPhysicianAffiliationCRDetails',
    getUpdateAGShiptoAffiliationCRDetails: 'getUpdateAGShiptoAffiliationCRDetails',
    getRemoveRetiredFlagCRDetails: 'getRemoveRetiredFlagCRDetails',
    getUpdateAGProfileCRDetails: 'getUpdateAGProfileCRDetails',
    getUpdateDirectAlignmentChangeCRDetails: 'getUpdateDirectAlignmentChangeCRDetails',

    getChangeRequestActivity: 'getChangeRequestActivity',
    getChangeRequestNotes: 'getChangeRequestNotes',
    getRejectReasonDropdownValues: 'getRejectReasonDropdownValues',
    getAssignUserDropdownValues: 'getAssignUserDropdownValues',
    assignCRToSoUser: 'assignCRToSoUser',

    //CR APIs submit
    createChangeRequest: 'createChangeRequest',
    getEmailThreadCRDetails: 'getEmailThreadCRDetails',
    updateEmailThreadCRDetails: 'updateEmailThreadCRDetails',

    //CR APIs approve/reject
    approveChangeRequest: 'approveChangeRequest',
    rejectChangeRequest: 'rejectChangeRequest',
    editChangeRequest: 'editChangeRequest',

    //Shipto APIs
    getStateDropDownValues: 'getStateDropDownValues',
    getShiptoSearchGrid: 'getShiptoSearchGrid',
    getShiptoRecentSalesSearchGrid: 'getShiptoRecentSalesSearchGrid',
    getShiptoDetails: 'getShiptoBasicDetails',
    getTimePeriodShiptoAllocation: 'getTimePeriodShiptoAllocation',
    getShiptoAllocation: 'getShiptoAllocation',
    getShiptoAgAffiliation: 'getShiptoAgAffiliation',
    getShiptoSalesFilters: 'getShiptoSalesFilters',
    getShiptoSales: 'getShiptoSales',
    getAllocationChangeReason: 'getAllocationChangeReason',
    getShiptoCurrentAllocation: 'getShiptoCurrentAllocation',

    //Physican APIs
    getPhysicianSearchFilters: 'getPhysicianSearchFilters',
    getPhysicianSearchGrid: 'getPhysicianSearchGrid',
    getPhysicianDetails: 'getPhysicianDetails',
    getPhysicianActiveAllocations: 'getPhysicianActiveAllocations',
    getPhysicianSalesFilters: 'getPhysicianSalesFilters',
    getPhysicianSales: 'getPhysicianSales',
    getPhysicianSearchExportGrid: 'getPhysicianSearchExportGrid',
    getUpdateTargetEligibilityStatusCRFormFilters: 'getUpdateTargetEligibilityStatusCRFormFilters',
    getAddNewPhysicianCRFormFilters: 'getAddNewPhysicianCRFormFilters',

    //AG APIs
    getAccountGroupSearchGrid: 'getAccountGroupSearchGrid',
    getAccountGroupDetails: 'getAccountGroupDetails',
    getAccountGroupShiptoAffiliation: 'getAccountGroupShiptoAffiliation',
    getAccountGroupPhysicianAffiliation: 'getAccountGroupPhysicianAffiliation',

    //ZipCode
    getZipcodeSearchGrid: 'getZipcodeSearchGrid',

    //Shared APIs
    getUserDetails: 'getUserInfo',
    fetchDocumentDetails: 'getDocuments',
    fetchHelpPageDetails: 'fetchHelpPageDetails',
    uploadDocument: 'uploadDocument',
    fetchDocuments: 'fetchDocuments',
    removeDocument: 'removeDocument',
    getGeographyMap: 'getGeographyMap',
    getHistoryOfChanges: 'getHistoryOfChanges',
    sendDeveloperErrorMail: 'sendDeveloperErrorMail',
    getCityStateInfoFromZipcode: 'getCityStateInfoFromZipcode',
    getCurrentPendingCRs: 'getCurrentPendingCRs',
};